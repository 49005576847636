@font-face {
  font-family: "Nunito";
  src: url("./Nunito/Nunito-VariableFont_wght.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("./Nunito/Nunito-Italic-VariableFont_wght.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
